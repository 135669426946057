<template>
  <div class="text-center">
    <v-dialog persistent v-model="open" width="40%" height="80%">
      <v-card class="py-4">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="h5 semiBold"
              >Tu usuario {{ name }} debe ser autorizado</span
            >
            <v-btn icon @click="close">
              <span class="material-symbols-rounded icon"> close </span>
            </v-btn>
          </div>
          <div class="d-flex mt-6">
            <span class="material-symbols-rounded icon" style="font-size: 40px">
              mail
            </span>
            <div class="ml-6">
              <span class="bold">
                Esta autorización está sujeta a la aprobación de tu supervisor. </span
              ><br /><br />
              <span class="mt-6">
                Por ahora recibirás un enlace de verificación en tu correo
                <b>{{ email }}</b> dentro de los próximos minutos, recuerda
                revisar la casilla de otros e-mails o spam.</span
              >
            </div>
          </div>
          <div class="d-flex flex-column justify-center align-center mt-4">
            <v-btn
              color="primary"
              class="text-none mt-4"
              width="214"
              height="41"
              rounded
              @click="close"
              >Entendido</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    dialog: true,
  }),
  props: {
    close: {
      type: Function,
      default: () => null,
    },
    open: {
      type: Boolean,
      require: true,
    },
    rut: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      default: "",
    },
    email: {
      type: [String, Number],
      default: "",
    },
    // PASSING ACTIONBUTTON AS FUNCTION, YOU MAY USE IT ON THE COMPONENTE WHERE ITS BEEN IMPORTED
    ActionButton: {
      type: Function,
      require: true,
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
  margin-right: 110px;
  z-index: 1;
}
#ComputerSvg {
  z-index: 10;
}
</style>
