<template>
  <v-snackbar v-model="snackbar" top color="red" right>
    {{ messege }} {{ msg }}
    <template v-slot:action="{ attrs }">
      <v-btn
        small
        color="white"
        icon
        class="mr-2"
        v-bind="attrs"
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script lang="ts">
export default {
  props: {
    snackbar: {
      type: Boolean,
    },
    msg: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("closeError");
    },
  },
  computed: {
    messege() {
      return this.$store.getters.errormsg;
    },
  },
};
</script>
